<template>
  <div v-if="currentPhoto" class="photos">
    <div class="edit-zone">
      <img ref="image" :src="currentPhoto.largePhoto"/>
    </div>
    <div class="thumbnail-zone" :class="{ 'is-editing': thumbEditing }">
      <div style="margin-bottom: 10px; color: white;">Thumbnail</div>
      <img :src="currentPhoto.thumbnail"/>
      <div class="thumb-preview" ref="thumbPreviewContainer">
      </div>
      <br/>
      <base-button v-if="!thumbEditing" class="animation-on-hover" type="info" @click="thumbEditing = true">Editeaza
        thumb
      </base-button>
      <base-button v-if="thumbEditing" :loading="thumbLoading" block type="success" @click="saveThumb">Salveaza
      </base-button>
      <base-button v-if="thumbEditing" block type="danger" @click="thumbEditing = false">Anuleaza</base-button>
      <br/>
      <hr/>
      <base-button v-if="!thumbEditing" :loading="acceptLoading" block type="success" @click="acceptPhoto">Accepta
      </base-button>
      <base-button v-if="!thumbEditing" block type="danger" @click="rejectModalOpened = true">Respinge</base-button>
    </div>

    <modal :show="rejectModalOpened" :show-close="false">
      <h6 slot="header" class="modal-title" id="modal-title-default">Alege motiv</h6>
      <card>
        <form role="form">
          <base-input>
            <select class="form-control" v-model="rejectReason">
              <option value="Nu este clara">Nu este clara</option>
              <option value="Apar mai multe persoane">Apar mai multe persoane</option>
              <option value="Poza luata de pe alt site">Poza luata de pe alt site</option>
              <option value="Contine watermark de pe alt site">Contine watermark de pe alt site</option>
              <option value="Apare alta persoana">Apare alta persoana</option>
            </select>
          </base-input>
          <div class="text-center">
            <base-button type="default" class="my-4" style="margin-right: 20px;" @click="rejectModalOpened = false">
              Anuleaza
            </base-button>
            <base-button type="primary" :loading="rejectLoading" class="my-4" @click="rejectPhoto">Respinge
            </base-button>
          </div>
        </form>
      </card>
    </modal>
  </div>
</template>

<script>
import get from 'lodash/get'
import {getPhotoUrl} from "@/utils/functions";
import Modal from "@/components/Modal";
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'

export default {
  name: "Photos",
  components: {
    Modal,
  },
  mounted() {
    this.getNextPhoto()
  },
  beforeDestroy() {
    this.destroyCropper()
  },
  data: () => ({
    currentPhoto: null,
    rejectModalOpened: false,
    rejectReason: '',
    rejectLoading: false,
    thumbCropped: '',
    thumbEditing: false,
    thumbLoading: false,
    acceptLoading: false,
  }),
  watch: {
    thumbEditing(isEditing) {
      if (isEditing) {
        this.createCropperInstance()
      } else {
        this.destroyCropper()
      }
    }
  },
  methods: {
    destroyCropper() {
      if (this.cropperInstance) {
        this.cropperInstance.destroy()
      }
    },
    createCropperInstance() {
      if (this.cropperInstance) {
        this.cropperInstance.destroy()
      }

      this.cropperInstance = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        guides: false,
        background: false,
        viewMode: 1,
        preview: this.$refs.thumbPreviewContainer
      })
    },
    saveThumb() {
      if (this.thumbLoading) return false
      this.thumbLoading = true

      const thumbData = this.cropperInstance
        .getCroppedCanvas({
          width: 200,
          height: 200,
        })
        .toDataURL('image/jpeg')
      this.$axios.post('admin/editPhoto', {
        photoId: this.currentPhoto.id,
        thumb: thumbData.split('base64,')[1]
      }).then(({data}) => {
        this.thumbLoading = false
        this.thumbEditing = false
        this.currentPhoto.thumbnail = getPhotoUrl(data.photo.thumbnail)
      })
    },
    rejectPhoto() {
      if (this.rejectLoading || !this.rejectReason) return false
      this.rejectLoading = true

      this.$axios.post('admin/photoStatus', {
        photoId: this.currentPhoto.id,
        statusId: this.photoStatuses.Rejected.id,
        reason: this.rejectReason,
      }).then(({data}) => {
        this.rejectLoading = false
        this.rejectModalOpened = false
      })
    },
    acceptPhoto() {
      if (this.acceptLoading) return false
      this.acceptLoading = true

      this.$axios.post('admin/photoStatus', {
        photoId: this.currentPhoto.id,
        statusId: this.photoStatuses.Accepted.id,
      }).then(({data}) => {
        this.getNextPhoto()
      })
    },
    getNextPhoto() {
      this.$axios.get('admin/photo', {
        params: {
          photoStatusTypeId: this.photoStatuses.InReview.id,
          number: 1,
        }
      }).then(({data}) => {
        const photo = get(data.photoList, '[0]')
        if (photo) {
          this.currentPhoto = {
            ...photo,
            thumbnail: getPhotoUrl(photo.thumbnail),
            largePhoto: getPhotoUrl(photo.largePhoto),
          }
        } else {
          this.currentPhoto = null
        }

        this.acceptLoading = false
      })
    },
  },
  computed: {
    photoStatuses() {
      return this.$store.getters['constants/keyedPhotoStatusType']
    }
  },
}
</script>

<style lang="scss">
.photos {
  display: flex;

  .thumbnail-zone {
    flex: 0 0 200px;
    width: 200px;
    margin-left: 20px;

    img {
      border-radius: 5px;
    }

    .thumb-preview {
      width: 200px;
      height: 200px;
      overflow: hidden;
    }

    &:not(.is-editing) {
      .thumb-preview {
        display: none;
      }
    }

    &.is-editing {
      > img {
        display: none;
      }
    }
  }

  .edit-zone {
    flex: 1;
    height: 800px;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  img {
    display: block;
  }
}
</style>
